import { types as api } from '@mesa-labs/mesa-api';
import * as types from '@mesa-labs/mesa-api/dist/types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DownloadButton, PaginationFooter, Search, Table, } from '@mesa-labs/mesa-ui';
import { ColumnProp } from '@mesa-labs/mesa-ui/dist/components/Table';

import { useNavigate } from 'react-router-dom';
import { DefaultPaginationLimits } from './PaginationLimits';
import { exportAllExternalClients, useGetAllExternalClientsQuery } from '../../redux/api/externalVendors';
import { asExportFilter } from '../../utils';
import { useDispatch, useSelector } from '../../redux/hooks';
import {
  updateLimit,
  updatePage,
  updateSearchTerm,
  updateSortDirection,
  updateSortField,
  updateTotalPages,
} from '../../redux/slices/externalClients';
import { FilterCell, FilterControls, FilterRow, FilterSection, FilterTitle } from './Filters';
import PartnerSelect from './PartnerSelect';
import useSyncQueryStringParamsToRedux from '../../hooks/useSyncQueryStringParamsToRedux';

const ClientsPageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ExternalClientColumns: ColumnProp[] = [
  {
    key: 'partnerName',
    sortField: 'partnerId',
    label: 'Partner',
    width: '10%',
  },
  {
    key: 'externalClientId',
    sortField: 'externalClientId',
    label: 'External Client Id',
    width: '10%',
  },
  {
    key: 'externalClientName',
    sortField: 'externalClientName',
    label: 'External Client Name',
    width: '20%',
  },
  {
    key: 'segment',
    sortField: 'segment',
    label: 'Client Segment',
    width: '10%',
  },
  {
    key: 'totalInvoiceSpendT12m',
    sortField: 'totalInvoiceSpendT12m',
    label: 'Total Invoice Spend (T12M)',
    type: 'currency',
    width: '20%',
  },
  {
    key: 'weightedAveragePaymentTenorT12m',
    sortField: 'weightedAveragePaymentTenorT12m',
    label: 'Weighted APT (T12M)',
    width: '20%',
  },
  {
    key: 'exclusionCode',
    label: 'Exclusion Code',
    width: '10%',
  },
];

type ExternalClientVendorsPageTemplate = {
  businessEntityScope: api.BusinessEntityScope;
};

function ExternalClientsPageTemplate(props: ExternalClientVendorsPageTemplate): React.ReactElement {
  const { businessEntityScope } = props;
  const qualifier = businessEntityScope === api.BusinessEntityScope.External ? 'external' : 'internal';

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [partner, setPartner] = useState<api.Partners>(api.Partners.JLL);
  const searchTerm = useSelector((state) => state.externalClients.searchTerm);
  const page = useSelector((state) => state.externalClients.page) || 1;
  const totalPages = useSelector((state) => state.externalClients.totalPages);
  const limit = useSelector((state) => state.externalClients.limit) || DefaultPaginationLimits[0];
  const sortField = useSelector((state) => state.externalClients.sortField);
  const sortDirection = useSelector((state) => state.externalClients.sortDirection);

  useSyncQueryStringParamsToRedux({ sliceName: 'externalClients' });

  const [searchInput, setSearchInput] = useState<string | undefined>(searchTerm);

  const filter: types.ExternalClientFilterParams & { partnerId: number } = {
    partnerId: partner,
    sortField,
    sortDirection,
    limit: limit.value,
    page,
    search: searchTerm,
    businessEntityScope
  };

  const {
    data: {
      data: clients = [],
      total: totalClients,
    } = {},
  } = useGetAllExternalClientsQuery(filter);

  const rows = clients.map((client) => ({
    partnerId: client.partnerId,
    partnerName: api.Partners[client.partnerId],
    id: `${client.partnerId}:${client.externalClientId}`,
    externalClientId: client.externalClientId,
    externalClientName: client.externalClientName,
    segment: client.segment,
    totalInvoiceSpendT12m: client.totalInvoiceSpendT12m,
    weightedAveragePaymentTenorT12m: client.weightedAveragePaymentTenorT12m,
    exclusionCode: client.exclusionCode,
    currency: 'USD', // TODO: MESA-1457: Multi-currency support
  }));

  useEffect(() => {
    if (totalClients !== undefined) {
      const limitValue = limit.value || limit as unknown as number || DefaultPaginationLimits[0].value;
      dispatch(updateTotalPages(Math.ceil(totalClients / limitValue)));
    }
  }, [limit, totalClients]);

  useEffect(() => {
    if (totalPages === undefined && page !== 1) {
      dispatch(updatePage(1));
    }
  }, []);

  return (
    <ClientsPageContainer>
      <FilterSection
        filterControl={(
          <FilterControls>
            <Search
              placeholder="Search by External Client Name"
              value={searchInput || ''}
              onChange={(value) => setSearchInput(value)}
              onSubmit={(value) => dispatch(updateSearchTerm(value))}
              onFinalize={(value) => dispatch(updateSearchTerm(value))}
              expanded
              width="550px"
            />
            {/* TODO: remove ! once backend supports optional partner ID */}
            <DownloadButton
              text="Download CSV"
              data={() => exportAllExternalClients(partner!, {
                ...asExportFilter(filter),
              })}
              fileName="external-clients.csv"
            />
          </FilterControls>
        )}
      >
        <FilterRow>
          <FilterCell>
            <FilterTitle>Partner</FilterTitle>
            <PartnerSelect
              selectedPartner={partner}
              onSelectedPartnerChange={(p) => setPartner(p!)}
            />
          </FilterCell>
        </FilterRow>
      </FilterSection>

      <Table
        columns={ExternalClientColumns}
        rows={rows}
        onRowClick={(row: Record<string, unknown>) => navigate(`/partners/${row.partnerId}/${qualifier}-clients/${row.externalClientId}`)}
        currentSortColumn={sortField}
        currentSortDirection={sortDirection}
        setSortField={(field: string) => dispatch(updateSortField(field))}
        setSortDirection={(direction: api.SortDirection) => dispatch(updateSortDirection(direction))}
        paginationComponent={(
          <PaginationFooter
            selectedLimit={limit}
            limits={DefaultPaginationLimits}
            onChange={(item) => {
              dispatch(updateLimit(item));
              dispatch(updatePage(1));
            }}
            currentPage={page}
            totalPages={totalPages || 1}
            onPrev={() => {
              dispatch(updatePage(page - 1));
            }}
            onNext={() => {
              dispatch(updatePage(page + 1));
            }}
          />
        )}
      />
    </ClientsPageContainer>
  );
}

export default ExternalClientsPageTemplate;
